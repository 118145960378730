import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import { Configure } from "react-instantsearch-dom";

import VillageHits from "@components/village/villageHits";
import { Query, SanityVillageConnection } from "@graphql-types";
import { Blocks, ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import VillageSearchWrapper from "@components/search/villageSearchWrapper";
import VillageFilters from "@components/village/villageFilters";
import {
  PageContentWrapper,
  StickyWrapper,
  VillageHitsWrapper,
} from "@styles/villageTemplateStyles";

interface Data extends Query {
  parentRegionVillages: SanityVillageConnection;
}
interface Props extends PageProps {
  data: Data;
}

export default function RegionTemplate({
  data: {
    sanityRegion,
    sanityHeader,
    allSanityVillage: { nodes },
    parentRegionVillages: { nodes: parentVillageNodes },
  },
  data,
}: Props) {
  if (sanityRegion == null)
    return <ErrorMsg data={sanityRegion} msg="Error fetching data for page" />;
  const [hitsLength, setHitsLength] = useState(0);
  const {
    slug,
    _type,
    pageColour,
    title,
    seo,
    blocks,
    isParentRegion,
    landingPageTitle,
    landingPageSubTitle,
  } = sanityRegion;
  usePageMeta(pageColour?.colour?.hex, _type);

  const { nodes: villageNodes } = data?.additionalSearchVillages;
  const combinedVillageNodes = [...nodes, ...villageNodes, ...parentVillageNodes];

  const sortedCombinedVillages = combinedVillageNodes.sort((a, b) =>
    a.title.localeCompare(b.title),
  );

  const initialFilter = isParentRegion
    ? `region.parentRegion.slug.current:${slug?.current}`
    : `combinedRegions.slug.current:${slug?.current}`;

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <VillageSearchWrapper
        indexName="villages"
        title={landingPageTitle ?? title}
        subTitle={landingPageSubTitle}
        config={<Configure hitsPerPage={1000} filters={initialFilter} />}
        hideMapButton
      >
        <PageContentWrapper>
          <StickyWrapper>
            <VillageFilters
              regionRefinement={slug?.current}
              useParentRegionRefinement={isParentRegion}
              hitsLength={hitsLength}
              defaultLength={nodes.length === 0 ? parentVillageNodes.length : nodes.length}
            />
          </StickyWrapper>
          <VillageHitsWrapper width="70%" tabletWidth="100%" backgroundColor="white">
            <VillageHits
              defaultData={
                Boolean(sortedCombinedVillages.length) ? sortedCombinedVillages : parentVillageNodes
              }
              setHitsLength={setHitsLength}
            />
          </VillageHitsWrapper>
        </PageContentWrapper>
      </VillageSearchWrapper>
      {blocks && <Blocks data={blocks} />}
    </div>
  );
}

export const query = graphql`
  query regionQuery($slug: String) {
    sanityHeader {
      ...sanityHeader
    }
    sanityRegion(slug: { current: { eq: $slug } }) {
      _type
      title
      slug {
        current
      }
      seo {
        ...sanitySeo
      }
      pageColour {
        ...sanityColorPicker
      }
      blocks {
        ...staticPageBlocks
      }
      isParentRegion
      parentRegion {
        title
        _id
        slug {
          current
        }
      }
      landingPageTitle
      landingPageSubTitle
    }
    allSanityVillage(
      filter: { region: { slug: { current: { eq: $slug } } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        ...sanityVillageMeta
      }
    }
    additionalSearchVillages: allSanityVillage(
      filter: { regions: { elemMatch: { slug: { current: { eq: $slug } } } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        ...sanityVillageMeta
      }
    }
    parentRegionVillages: allSanityVillage(
      filter: { region: { parentRegion: { slug: { current: { eq: $slug } } } } }
      sort: { order: ASC, fields: title }
    ) {
      nodes {
        ...sanityVillageMeta
      }
    }
  }
`;
